.html {
    height: 100%;
    width: 100%;
    margin: 0;
    font-family: Arial !important;
}

.information {
    color: #434A54;
    font-family: Tahoma, sans-serif;
}

.sectionHeader {
    font-size:2em;
}

.content {
    font-size: 1.2em;
}

@media screen and (max-width: 414px) {
    .header {
        height: 70vw !important;
    }
    .information {
        width: 100% !important;
    }
}

@media screen and (max-width: 1024px) {
    .header {
        height: 70vw !important;
    }
    .information {
        width: 100% !important;
    }
    .references {
        display: flex !important;
        flex-wrap: wrap !important;
        
        /* margin: 3.5rem 3rem 0rem 5rem; */
        margin: 0 !important;
        
        width: 100%;
        text-align: center;
        justify-content: center;
        gap: 3rem;
    }
    .reference {
        flex-direction: column !important;
        display: flex;
        font-weight: bold;
        padding-right: 0 !important;
        margin: 0.3rem 0 0.8rem 0rem;
        
    }
}


/*
margin-top
margin-right
margin-bottom
margin-left
*/
.references {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.reference {
    text-decoration: none;
    padding-right: 50px;
    color:white;
}

.header {
    height: 30vw;
    width: 100%;
    background-image: linear-gradient(to bottom, rgba(64, 76, 143, 0.52), rgba(26, 5, 20, 0.73)), url('../../images/forside_baggrund.png');
    background-size: cover;
    background-position: center;
    box-shadow: 0 1rem 2rem rgba(black, .2);
    position: relative;
}

.title {
    color: white;
    text-shadow: 2px 2px black;
    line-height: 50%;
    left: 50%;
    padding: 0 0 1% 0;
}

.title #location {
    position:relative;
    right: 35%;
    font-size: large;
}

.information {
    margin: auto;
    width: 60%;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
}

.center-fit {
    max-width: 100%;
    max-height: 100vh;
    margin: auto;
}

.footerReferences {
    margin-bottom: 1vw;
}

.footerReference {
    text-decoration:  none;
    color: black;
    margin-right: 3vw;
    margin-left: 3vw;
}

#footerName {
    font-size: x-large;
}
