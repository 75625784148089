.projects {
    display: flex;
    flex-direction: column;
}

.pictureAndTechnologies {
    flex-direction: column;
}

.projectInformation {
    padding-left: 2vw;
}

@media screen and (max-width: 1000px) {

    .projectImage {
        width: 100% !important;
        height: auto !important;
    }
}

.projectText {
    width: 80%;
    margin: auto;
}

.projectImage {
    height: 17vw;
    width: auto;
    box-shadow: 5px 5px 5px #ccc;
    padding-right: 1vw;
}

i{
    font-size: 1rem;
    margin-right: .5rem;
    line-height: 2rem;
}

#github {
    font-size: 2rem;
}


hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 2px solid #ccc;
    margin-top: 2em;
    margin-bottom: 2em;
    width: 100%;
}

ol {
    counter-reset: li; 
    list-style: none; 
    padding: 0;
    text-shadow: 0 1px 0 rgba(255,255,255,.5);
}

ol li {
    position: relative;
    display: block;
    padding: .4em .4em .4em 2em;
    margin: .5em auto;
    width: 50%;
    background: linear-gradient(to bottom, rgba(64, 76, 143, 0.52), rgba(26, 5, 20, 0.73)), url('../../images/forside_baggrund.png');
    color: #fff;
    text-decoration: none;
    border-radius: .3em;
    transition: .3s ease-out;
}

ol :hover:before {
     transform: rotate(360deg);
}

ol :before {
    content: counter(li);
    counter-increment: li;
    position: absolute;
    left: -1em;
    top: 50%;
    margin-top: -1.3em;
    background: #f06464;
    height: 2em;
    width: 2em;
    line-height: 2em;
    border: .3em solid #fff;
    text-align: center;
    font-weight: bold;
    border-radius: 2em;
    transition: all .3s ease-out;
}

#portfolio {
    margin-bottom: 5%;
}